import React from 'react';
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange,Rectangle,Clock,JulianDate} from '../../node_modules/cesium';
import '../../node_modules/cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";

class CesiumContainer extends React.Component {
  componentDidMount() {
    var viewer = new Viewer('cesiumContainer', {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      geocoder:false,
      timeline : true,
      animation : true,
      FullscreenButton: true,
      homeButton: true,
      vrButton: true,
      sceneModePicker:true,
      navigationHelpButton:true,
      clock: new Clock(),
    });


    // var scene = viewer.scene;
    // scene.globe.enableLighting = true;
    // scene.globe.scene3DOnly = true;
    // scene.shadows = true;


    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };






    // var kmlurl="/kml/output/output.kml";
    // var kmlurl="https://empire-dev3.kashika.net/kml/26/福岡県糸島市_2015高齢単身世帯数×201565歳以上割合.kml";
    var kmlurl="https://empire-dev3.kashika.net/kml/26/福岡県糸島市_H27高齢単身世帯数×H2765歳以上割合.kmz";

    
    // var kmlurl=this.props.location.state.dir+this.props.location.state.file,options;

    var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(kmlurl,options));
    // var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.location.state.dir+this.props.location.state.file,options));

    console.log(kmlurl);

    
    viewer.flyTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));
    viewer._cesiumWidget._creditContainer.style.display = "none";


  var currentTime = new JulianDate.fromIso8601("1990-07-11T16:00:00Z");
  viewer.clock.currentTime = currentTime;

/// Navigation /////
  var navoptions = {};
  navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
  navoptions.enableCompass = true;
  navoptions.enableZoomControls = true;
  navoptions.enableDistanceLegend = true;
  navoptions.enableCompassOuterRing = true;
  CesiumNavigation(viewer, navoptions);
///// Navigation ///

/// Checkbox /////


///// Checkbox ///


  }
 
  render() {
    return <div>
      <div id="cesiumContainer" />
      <div id="toolbar" />
      </div>;
  }
}

export default CesiumContainer

